// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import Tagify from "@yaireo/tagify/dist/tagify.esm";
import {openModal, closeModal, initDataTable, displayAlerts} from "../bo/bo";

Rails.start();
ActiveStorage.start();

window.Tagify = Tagify;
window.Rails = Rails;
window.openModal = openModal;
window.closeModal = closeModal;
window.initDataTable = initDataTable;
window.displayAlerts = displayAlerts;
