import MicroModal from "micromodal/dist/micromodal.es";
import {DataTable} from "simple-datatables";

export function openModal(modalId, cfg) {
    var modalConfig = cfg || {};
    modalConfig.disableScroll = true;
    MicroModal.show(modalId, modalConfig);
}

export function closeModal(modalId) {
    MicroModal.close(modalId);
}

export function initDataTable(table) {
    return new DataTable(table, {
        labels: {
            placeholder: "Rechercher...",
            searchTitle: "Rechercher dans les résultats",
            perPage: "résultats par page",
            noRows: "Aucun résultat",
            info: "Résultats {start} à {end} sur {rows}",
            noResults: "Aucun résultat pour cette recherche"
        }
    });
}

export function displayAlerts() {
    var alerts = document.querySelectorAll("#notice, #alert");
    if (alerts.length > 0) {
        var validationMsg = document.querySelector(".validation_markup");
        if (validationMsg) {
            validationMsg.scrollIntoView({
                behavior: 'smooth'
            });
        }
        setTimeout(function() {
            alerts[0].style = "transition: 1s; opacity: 0;";
            setTimeout(function() {
                alerts[0].style = "display: none;";
            }, 1000);
        }, 5000);
    }
}